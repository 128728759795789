<script setup lang="ts">
/**
 * Use like this `<Tooltip>{{ your tooltip </Tooltip>}}`
 * Parent element needs to have `relative` and `group` classes.
 * CSS styled in `assets/css/tailwind.css`
 */

const props = withDefaults(
  defineProps<{
    size?: string;
  }>(),
  {
    size: "12",
  }
);
</script>

<template>
  <span
    :class="`absolute w-auto p-2 m-2 min-w-max left-${props.size} rounded-md shadow-md text-white dark:text-background-100 bg-background-100 dark:bg-background-400 text-xs font-bold transition-all duration-100 delay-200 scale-0 origin-left group-hover:scale-100 z-10`"
  >
    <slot />
  </span>
</template>
